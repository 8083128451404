import React, { useState, useEffect } from 'react';

function ImageSlideshow() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    '/web-proto-1.png',
    '/web-proto-2.png',
    '/web-proto-3.png'
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 2600); // 2.6 seconds

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="relative w-full max-w-xl transform rotate-[10deg] hover:rotate-0 transition-all duration-500 
      hover:scale-105 perspective-1000 translate-z-0 hover:translate-z-12
      [transform-style:preserve-3d]">
      {images.map((src, index) => (
        <img 
          key={src}
          src={src}
          alt={`Application Interface ${index + 1}`}
          className={`absolute top-0 left-0 w-full h-auto rounded-lg transition-all duration-500
            shadow-[0_20px_50px_rgba(0,0,0,0.3)] hover:shadow-[0_30px_70px_rgba(0,0,0,0.4)]
            ${currentImageIndex === index ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}
        />
      ))}
      {/* Gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/10 to-transparent rounded-lg"></div>
    </div>
  );
}

function App() {
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [showNav, setShowNav] = useState(true);
  const [showSection, setShowSection] = useState(true);

  useEffect(() => {
    // Hide nav and scroll content after animations complete
    const timer = setTimeout(() => {
      setShowNav(false);
      // Add small delay to hide section after nav animation
      setTimeout(() => {
        setShowSection(false);
      }, 300); // Wait for nav transition to complete
      
      // Scroll the heading to nav height position
      const navHeight = 76;
      const heading = document.querySelector('.main-heading');
      if (heading) {
        const headingPosition = heading.getBoundingClientRect().top + window.pageYOffset - navHeight;
        window.scrollTo({
          top: headingPosition,
          behavior: 'smooth'
        });
      }
    }, 3500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible = prevScrollPos > currentScrollPos;

      setPrevScrollPos(currentScrollPos);
      setVisible(visible);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  return (
    <div className="min-h-screen flex flex-col">
      {showSection && (
        <section className="w-full h-24">
          {/* Animated Header */}
          {showNav && (
            <div className={`h-[76px] w-full bg-[#FA660F] transition-all duration-300 ease-in-out fixed z-50
              animate-slide-in-top animate-fade-out
              ${visible ? 'translate-y-0' : '-translate-y-full'}`}
            />
          )}
        </section>
      )}
      <div className="min-h-screen flex flex-col bg-[#FF5722] relative">
        {/* Main Content */}
        <div className="flex-grow pt-20 pb-[80px] relative">
          {/* New dot pattern background layer */}
          <div className="absolute inset-0 z-0 bg-[linear-gradient(to_bottom,rgba(0,0,0,0.1),transparent),radial-gradient(rgba(0,0,0,0.3)_1px,transparent_1px)] [background-size:100%_100%,50px_50px]"></div>
          {/* Background pattern layer */}
          <div className="absolute inset-0 z-10 bg-[linear-gradient(to_bottom,rgb(0,0,0,0.1),transparent),radial-gradient(#000_1px,transparent_1px)] [background-size:100%_100%,16px_16px]"></div>
          {/* Gradient layer */}
          <div className="absolute inset-0 z-20 bg-gradient-main opacity-50"></div>
          {/* Content */}
          <main className="relative z-30 max-w-7xl mx-auto px-6 md:px-12 py-16">
            <div className="flex flex-col lg:flex-row gap-12 items-center">
              {/* Left Column */}
              <div className="flex-1 text-center lg:text-left">
                <h1 className="main-heading text-4xl sm:text-5xl md:text-6xl lg:text-8xl font-bold text-black mb-8">
                  Westify IT AB
                  <span className="block text-white">
                    Design & Utveckling
                  </span>
                </h1>
                <div className="animate-slide-in mt-8">
                  <div className="flex items-center justify-center lg:justify-start">
                    <a 
                      href="https://drafts.westify.se/"
                      className="bg-black text-white px-6 sm:px-8 lg:px-10 py-4 lg:py-5 text-xl sm:text-2xl lg:text-3xl font-extrabold rounded-lg hover:bg-gray-800 
                        transition-all duration-300 hover:shadow-lg hover:-translate-y-0.5"
                    >
                      Hör av er!
                    </a>
                  </div>
                </div>
              </div>

              {/* Right Column - Image - Hidden on mobile */}
              <div className="flex-1 hidden lg:flex items-center justify-center -mt-[21rem] ml-12">
                <ImageSlideshow />
              </div>
            </div>

            {/* Bottom Section */}
            <div className="mt-16 lg:mt-32 text-center bg-black/10 backdrop-blur-sm rounded-xl p-8 md:p-12">
              <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-4">
                Skapa din <span className="text-white lg:text-[#FF5722] drop-shadow-[0_2px_2px_rgba(0,0,0,0.3)]">digitala</span>
              </h2>
              <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold">
                närvaro med oss
              </h2>
              <p className="mt-6 text-lg sm:text-xl text-black/90 max-w-2xl mx-auto">
                Vi utvecklar moderna och användarvänliga lösningar
              </p>
            </div>
          </main>
        </div>

        {/* Footer */}
        <footer className="w-full h-[80px] bg-black text-white mt-auto">
          <div className="max-w-7xl mx-auto px-6 md:px-12 h-full flex items-center justify-center">
            <div className="text-sm font-semibold text-center">
              <p className="mb-1">Org.nr: 559462-9932</p>
              <p>Innehar F-skattsedel</p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
